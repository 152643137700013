<template>
  <div class="container">
    <!-- PC 版 -->
    <div v-if="isPhone">
      <section class="section-carousel">
        <a-carousel :autoplay="true" :pauseOnHover="true" :autoplaySpeed="5000" :touchMove="false">
          <div class="section-carousel-div" v-for="(item, index) in carouselImg" :key="index">
            <img :src="item.url" alt="" class="section-carousel-div-img">
            <h1 class="section-carousel-title">{{ item.title }}</h1>
            <a-button-group class="section-carousel-group">
              <a-button class="section-carousel-left" type="primary" @click="toFreeTrial">{{
                $t('Common.freeTrial')
              }}
              </a-button>
              <a-button class="section-carousel-right" type="primary" @click="toFreeTrial">
                <a-icon type="right" />
              </a-button>
            </a-button-group>
            <a-button type="primary" @click="toEvaluationForm" class="ToEvaluationFormButton">{{
                $t('ProductCenter.FreeDownloadOfSoftware') }}</a-button>
          </div>
        </a-carousel>
      </section>
      <section class="section-company">
        <img src="../assets/img/index/enterpriseBackground.png" alt="LinkingInnovations" class="section-company-logo">
        <div class="section-company-right">
          <div class="section-company-right-title">{{ $t('Index.section-company.title') }}</div>
          <div class="section-company-right-line" />
          <div class="section-company-right-information">{{ $t('Index.section-company.informationOne') }}</div>
          <div class="section-company-right-information">{{ $t('Index.section-company.informationTwo') }}</div>
          <div class="section-company-right-information">{{ $t('Index.section-company.tooltip') }}</div>
        </div>
      </section>
      <section class="section-product">
        <div class="section-product-title">{{ $t('Index.section-product') }}</div>
        <div class="section-product-footer">
          <img src="../assets/img/index/process.png" alt="LinkingInnovations" class="linshiphoto" />
          <div class="section-product-footer-copy">
            <div class="section-product-footer-content"
              :class="{ 'section-product-footer-content-mouseover': index === activeProduct }"
              v-for="(item, index) in productList" :key="index">
              <!-- <img :src="index === activeProduct ? item.urls : item.url" alt="LinkingInnovations"
            class="section-product-logo" @mouseenter="mouseEnterProduct(index)"> -->
              <div class="section-product-footer-content-footer">
                <div class="section-product-footer-content-title" @mouseenter="mouseEnterProduct(index)">{{
                  item.title
                }}
                </div>
                <div class="section-product-footer-content-display">
                  <!-- <div class="section-product-footer-content-display-title" v-if="index === 2">
                {{ item.titleInformationTitleAI }}
              </div>
              <div class="section-product-footer-content-information" v-if="index === 2">{{ item.data }}</div>
              <div class="section-product-footer-content-display-title">{{ item.titleInformationTitle }}</div> -->
                  <div class="section-product-footer-content-information">{{ item.data }}</div>
                  <!-- <router-link class="section-product-footer-content-link" to="/ProductCenter">{{
                  $t('Index.checkDetails')
              }}
                <a-icon type="right" class="section-product-footer-center-arrow" />
              </router-link> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section-partners">
        <div class="section-partners-title">{{ $t('Index.section-partners') }}</div>
        <div class="section-partners-company">
          <div class="section-partners-company-div" v-for="(item, index) in partnersCompany" :key="index">
            <img :class="'section-partners-company-logo' + index" class="section-partners-company-logo" alt=""
              :src="item" />
          </div>
        </div>
      </section>
    </div>
    <!-- 移动端 -->
    <div v-else>
      <section class="section-carousel">
        <a-carousel :autoplay="true" :pauseOnHover="true" :autoplaySpeed="5000" :touchMove="false">
          <div class="section-carousel-div" v-for="(item, index) in carouselImg" :key="index">
            <img :src="item.url" alt="" class="section-carousel-div-img">
            <h1 class="section-carousel-title">{{ item.title }}</h1>
            <a-button type="primary" @click="toEvaluationForm" class="ToEvaluationFormButton">{{
                $t('ProductCenter.FreeDownloadOfSoftware') }}</a-button>
          </div>
        </a-carousel>
      </section>
      <section class="section-company">
        <div class="section-company-right-title">{{ $t('Index.section-company.title') }}</div>
        <div class="section-company-right-line" />
        <div class="section-company-flex">
          <img src="../assets/img/index/enterpriseBackground.png" alt="LinkingInnovations" class="section-company-logo">
          <div class="section-company-right-information">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{
            $t('Index.section-company.informationOne')
          }}{{ $t('Index.section-company.informationTwo') }}</div>
        </div>
        <div class="section-company-right-information m-section-company-right-information">{{
          $t('Index.section-company.tooltip')
        }}</div>
      </section>
      <section class="section-product">
        <div class="section-product-title">{{ $t('Index.section-product') }}</div>
        <div class="section-product-footer">
          <img src="../assets/img/index/process.png" alt="LinkingInnovations" class="linshiphoto" />
          <div class="section-product-footer-copy">
            <div class="section-product-footer-content"
              :class="{ 'section-product-footer-content-mouseover': index === activeProduct }"
              v-for="(item, index) in productList" :key="index">
              <div class="section-product-footer-content-footer">
                <div class="section-product-footer-content-title" @click="mouseEnterProduct(index)">{{
                  item.title
                }}
                </div>
                <div class="section-product-footer-content-display">
                  <div class="section-product-footer-content-information">{{ item.data }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section-partners">
        <div class="section-partners-title">{{ $t('Index.section-partners') }}</div>
        <div class="section-partners-company">
          <div class="section-partners-company-div" v-for="(item, index) in partnersCompany" :key="index">
            <img :class="'section-partners-company-logo' + index" class="section-partners-company-logo" alt=""
              :src="item" />
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      activeProduct: 1,
      isPhone: true,
      carouselImg: [
        {
          url: require("../assets/img/index/carouseImgOne.jpg"),
          title: this.$t('Index.carouse.CarouselTitleOne')
        },
        {
          url: require("../assets/img/index/carouseImgTwo.jpg"),
          title: this.$t('Index.carouse.CarouselTitleTwo')
        },
        {
          url: require("../assets/img/index/carouseImgThree.jpg"),
          title: this.$t('Index.carouse.CarouselTitleThree')
        }
      ],
      partnersCompany: [
        require("../assets/img/partnerslogo/partnersOne.png"),
        require("../assets/img/partnerslogo/partnersTwo.png"),
        require("../assets/img/partnerslogo/partnersThree.png"),
        require("../assets/img/partnerslogo/partnersFour.png"),
        require("../assets/img/partnerslogo/partnersFive.png"),
        require("../assets/img/partnerslogo/partnersSix.png"),
        require("../assets/img/partnerslogo/partnersSeven.png"),
        require("../assets/img/partnerslogo/partnersEight.png"),
        require("../assets/img/partnerslogo/partnersNine.png"),
        require("../assets/img/partnerslogo/partnersTen.png"),
        require("../assets/img/partnerslogo/partnersEleven.png"),
        require("../assets/img/partnerslogo/partnersTwelve.png"),
        require("../assets/img/partnerslogo/partnersThirteen.png"),
        require("../assets/img/partnerslogo/partnersFourteen.png"),
        require("../assets/img/partnerslogo/partnersFiveteen.png"),
        require("../assets/img/partnerslogo/partnersSixteen.png"),
        require("../assets/img/partnerslogo/partnersSeventeen.png"),
        require("../assets/img/partnerslogo/partnersEighteen.png"),
        require("../assets/img/partnerslogo/partnersNineteen.png"),
        require("../assets/img/partnerslogo/partnersTwenty.png"),
      ],
      productList: [
        {
          title: this.$t('Index.productList.productListOne.title'),
          titleInformationTitle: this.$t('Index.productList.productListOne.titleInformationTitle'),
          data: this.$t('Index.productList.productListOne.data'),
          url: require("../assets/img/index/screenUnselect.png"),
          urls: require("../assets/img/index/screenSelect.png")
        },
        {
          title: this.$t('Index.productList.productListTwo.title'),
          titleInformationTitle: this.$t('Index.productList.productListTwo.titleInformationTitle'),
          data: this.$t('Index.productList.productListTwo.data'),
          url: require("../assets/img/index/diagnoseUnselect.png"),
          urls: require("../assets/img/index/diagnoseSelect.png")
        },
        {
          title: this.$t('Index.productList.productListThree.title'),
          titleInformationTitle: this.$t('Index.productList.productListThree.titleInformationTitle'),
          data: this.$t('Index.productList.productListThree.data'),
          url: require("../assets/img/index/treatUnselect.png"),
          urls: require("../assets/img/index/treatSelect.png")
        },
        {
          title: this.$t('Index.productList.productListFour.title'),
          titleInformationTitle: this.$t('Index.productList.productListFour.titleInformationTitle'),
          data: this.$t('Index.productList.productListFour.data'),
          url: require("../assets/img/index/accompanyUnselect.png"),
          urls: require("../assets/img/index/accompanySelect.png")
        },
        {
          title: this.$t('Index.productList.productListFive.title'),
          titleInformationTitle: this.$t('Index.productList.productListFive.titleInformationTitle'),
          data: this.$t('Index.productList.productListFive.data'),
          url: require("../assets/img/index/accompanyUnselect.png"),
          urls: require("../assets/img/index/accompanySelect.png")
        },
        {
          title: this.$t('Index.productList.productListSix.title'),
          titleInformationTitle: this.$t('Index.productList.productListSix.titleInformationTitle'),
          data: this.$t('Index.productList.productListSix.data'),
          url: require("../assets/img/index/accompanyUnselect.png"),
          urls: require("../assets/img/index/accompanySelect.png")
        }
      ]
    }
  },
  created() {
    if (
      window.navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    ) {
      this.isPhone = false
      console.log(this.isPhone);
    }
  },
  methods: {
    mouseEnterProduct(index) {
      this.activeProduct = index;
    },
    //免费试用按钮点击事件
    toFreeTrial() {
      let routeUrl = this.$router.resolve({
        path: "/FreeTrial"
      });
      window.open(routeUrl.href, '_blank');
    },
    toEvaluationForm() {
      this.$router.push("/evaluation-form")
    }
  }
}
</script>

<style scoped lang="less">
.linshiphoto {
  display: block;
  width: 100%;
  height: 226px;
  margin-top: 120px;
}

.section-carousel-div {
  width: 100%;
  height: 600px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.section-carousel-div-img {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  top: 0;
  left: 0;
}

.section-carousel-title {
  position: absolute;
  font-size: 40px;
  line-height: 50px;
  color: #FFFFFF;
  top: 200px;
  white-space: pre-wrap;
  text-align: left;
  left: 190px;
}

.section-carousel-freeTrial {
  position: absolute;
}

/deep/ .ant-carousel .slick-dots-bottom {
  position: absolute;
  bottom: 45px;
}

/deep/ .ant-carousel .slick-dots li {
  margin-right: 26px;
  margin-left: 0;
}

/deep/ .ant-carousel .slick-dots li button {
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
}

/deep/ .ant-carousel .slick-dots li.slick-active button {
  width: 20px;
  height: 20px;
  background: #FFFFFF;
  border-radius: 50%;
}

.section-company {
  width: 100%;
  padding: 160px 240px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.section-company-logo {
  width: 550px;
  height: 360px;
}

.section-company-right {
  width: 784px;
}

.section-company-right-title {
  text-align: left;
  font-size: 40px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 78px;
}

.section-company-right-line {
  width: 220px;
  height: 3px;
  margin-top: 22px;
  margin-bottom: 38px;
  background: #22DCA1;
}

.section-company-right-information {
  text-align: left;
  font-size: 20px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 28px;
  margin-bottom: 20px;
}

.section-product {
  display: none;
  width: 100%;
  padding: 0 240px 160px 240px;
}

.section-product-title {
  height: 78px;
  line-height: 78px;
  font-size: 40px;
  font-weight: 600;
  color: #FFFFFF;
}

.section-product-footer .section-product-footer-copy {
  display: flex;
  justify-content: space-between;
}

.section-product-footer-content {
  width: 25%;
  height: 604px;
  margin-top: 60px;
  box-sizing: border-box;
  transition: all 0.8s;
}

.section-product-logo {
  display: block;
  width: 100%;
  height: 230px;
  margin: 0 auto 64px;
  transition: all 1s;
}

.section-product-footer-content-footer {
  position: relative;
}

.section-product-footer-content-title {
  height: 86px;
  background: #555555;
  font-size: 26px;
  font-weight: bold;
  line-height: 86px;
  color: #FFFFFF;
  text-align: left;
  padding-left: 44px;
}

.section-product-footer-content-information {
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  // margin-top: 10px;
  margin-top: 0;
  line-height: 1.5;
  padding: 0 10px 0 20px;
  color: rgba(33, 33, 33, 0.5);
}

.section-product-footer-content-link {
  text-align: right;
  height: 50px;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  color: #212121;
  line-height: 50px;
  margin-top: 20px;
  margin-right: 28px;
  display: none;
}

.section-product-footer-content-display {
  height: 0;
  box-sizing: border-box;
  opacity: 0;
  background: #3ebb93;
  padding-top: 20px;
  transition: all 1s;
}

.section-product-footer-content-display-title {
  text-align: left;
  font-size: 18px;
  padding: 20px 6px 0 20px;
  line-height: 24px;
  color: rgba(33, 33, 33, 0.8);
  font-weight: 400;
}

.section-product-footer-content-mouseover {
  .section-product-footer-content-display {
    height: 100px;
    opacity: 1;
  }

  .section-product-footer-content-title {
    background: #3ebb93;
    color: #212121;
  }

  .section-product-footer-content-link {
    display: block;
  }

}

/deep/ .section-product-footer-center-arrow .anticon svg {
  font-size: 16px;
}

.section-partners {
  width: 100%;
  padding: 0 240px 160px 240px;
}

.section-partners-title {
  height: 78px;
  line-height: 78px;
  font-size: 40px;
  font-weight: 600;
  color: #FFFFFF;
  margin-bottom: 90px;
}

.section-partners-company {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: rgba(216, 216, 216, 0.1);
  padding: 70px 40px 14px 30px;
}

.section-partners-company-div {
  width: 17%;
  text-align: left;
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}

.section-partners-company-logo {
  max-width: 100%;
  height: 60px;
}

.section-partners-company-logo2 {
  height: 65px;
}

.section-partners-company-logo7 {
  height: 50px;
}

.section-partners-company-logo8 {
  height: 72px;
}

.section-partners-company-logo10 {
  height: 64px;
}

.section-partners-company-logo11 {
  height: 20px;
}

.section-partners-company-logo12 {
  height: 56px;
}

.section-partners-company-logo13 {
  height: 50px;
}

.section-partners-company-logo14 {
  height: 70px;
}

.section-partners-company-logo15 {
  height: 70px;
}

.section-partners-company-logo17 {
  height: 74px;
}

.section-partners-company-logo18 {
  height: 45px;
}

.section-carousel-left {
  width: 180px;
  height: 60px;
  background: #22DCA1;
  font-size: 18px;
  font-weight: 600;
  color: #212121;
}

.section-carousel-right {
  width: 60px;
  height: 60px;
  background: #32F1B5;
  color: #212121;
}

/deep/ .section-carousel-right .anticon svg {
  font-size: 24px;
}

.section-carousel-group {
  position: absolute;
  bottom: 100px;
  left: 194px;
}

.ToEvaluationFormButton {
  padding: 0;
  width: 180px;
  height: 60px;
  font-size: 18px;
  color: #212121;
  background: #22DCA1;
  font-weight: 600;
  position: absolute;
  bottom: 100px;
  left: 480px;
}

@media screen and (max-width: 768px) {

  .ToEvaluationFormButton {
    padding: 0;
    width: 90px;
    height: 20px;
    font-size: 10px;
    color: #212121;
    position: absolute;
    left: 20px;
    bottom: 30px;
  }

  .section-carousel-div {
    height: 140px;
  }

  .section-carousel-title {
    font-size: 10px;
    line-height: 16px;
    top: 40px;
    left: 20px;
  }

  .section-company {
    width: 100%;
    padding: 12px;
    display: block;
  }

  .section-company-flex {
    display: flex;
  }

  .section-company-right {
    width: 100%;
  }

  .section-company-logo {
    width: 90px;
    height: 74px;
  }

  .m-section-company-right-information {
    margin-left: 0 !important;
  }

  .section-company-right-title {
    text-align: left;
    font-size: 12px;
    font-weight: 600;
    color: #FFFFFF;
    margin-top: 0px;
    line-height: 12px;
  }

  .section-company-right-line {
    width: 150px;
    height: 1px;
    margin: 6px 0 8px;
    text-align: left;
    background: #22DCA1;
  }

  .section-company-right-information {
    text-align: left;
    font-size: 8px;
    line-height: 13px;
    font-weight: 400;
    color: #FFFFFF;
    margin-left: 6px;
    margin-bottom: 0;
  }

  .section-product-title {
    height: 14px;
    line-height: 14px;
    font-size: 12px;
    font-weight: 600;
    color: #FFFFFF;
  }

  .section-product {
    width: 100%;
    padding: 12px 20px;
  }

  .section-product-footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .section-product-footer-content {
    width: 100%;
    height: 46px;
    margin-top: 10px;
    transition: all 0.8s;
  }

  .section-product-logo {
    display: block;
    width: 98%;
    height: 100px;
    margin: 10px auto;
  }

  .section-product-footer-content-title {
    height: 16px;
    padding: 0;
    line-height: 16px;
    font-size: 8px;
    font-weight: 600;
    color: #FFFFFF;
    text-align: center;
    transition: all 0.8s;
  }

  .section-product-footer-content-link {
    display: none;
    cursor: pointer;
    font-size: 9px;
    font-weight: 600;
    color: #212121;
    height: 20px;
    line-height: 20px;
    margin-top: 20px;
  }

  .section-product-footer-content-display {
    position: absolute;
    margin-left: -20px;
    height: 0;
    opacity: 0;
    box-sizing: border-box;
    background: #22DCA1;
    padding-top: 4px;
    transition: all 1s;
  }

  .section-product-footer-content-information {
    width: 80px;
    text-align: center;
    font-size: 8px;
    line-height: 1.2;
    font-weight: 600;
    margin-top: 0;
    padding: 0 2px;
    white-space: pre-wrap;
    color: rgba(33, 33, 33, 0.5);
  }

  .section-product-footer-content-display-title {
    text-align: left;
    font-size: 12px;
    padding: 0 10px;
    color: rgba(33, 33, 33, 0.8);
    font-weight: 400;
  }

  .section-product-footer-content-mouseover {
    .section-product-footer-content-display {
      height: 30px;
      opacity: 1;
    }

    .section-product-footer-content-title {
      background: #22DCA1;
      color: #212121;
    }

    .section-product-footer-content-link {
      display: block;
    }

  }

  .section-partners {
    width: 100%;
    padding: 0 12px 10px 12px;
  }

  .section-partners-title {
    height: 14px;
    line-height: 14px;
    font-size: 12px;
    font-weight: 600;
    color: #FFFFFF;
    margin: 8px 0 10px 0;
  }

  .section-partners-company {
    padding: 6px;
  }

  .section-partners-company-div {
    width: 33%;
    text-align: left;
    display: flex;
    align-items: center;
    padding: 0 6px 4px 0;
    margin: 0;
  }

  .section-partners-company-logo {
    margin: 0 0 4px 0;
    max-width: 100%;
    height: 24px;
  }

  /deep/ .ant-carousel .slick-dots-bottom {
    position: absolute;
    bottom: 14px;
  }

  /deep/ .ant-carousel .slick-dots li {
    margin-right: 24px;
    margin-left: 0;
  }

  /deep/ .ant-carousel .slick-dots li button {
    width: 8px;
    height: 8px;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 50%;
  }

  /deep/ .ant-carousel .slick-dots li.slick-active button {
    width: 8px;
    height: 8px;
    background: #FFFFFF;
    border-radius: 50%;
  }

  .linshiphoto {
    display: block;
    width: 100%;
    height: 50px;
    margin-top: 10px;
  }
}
</style>